#video {
  position: relative;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  filter: contrast(50%);
}

.hero {
  height: 100vh;
  width: 100vw;
  object-fit: contain;
}

.hero .content {
  text-align: center;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 30%;
  top: 30vh;
}

.hero .content h1 {
  font-size: 4rem;
  color: #fff;
}

.hero p {
  font-size: 1.8rem;
  font-weight: 200;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  color: #fff;
}
/* 
.btn-container {
  top: 34%;
} */

.hero .btn {
  margin: 1rem 0.2rem;
  margin-top: 5%;
}

@media screen and (max-width: 640px) {
  .content h1 {
    font-size: 3rem;
  }

  .content p {
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
  }
}
