.Heading1 {
  padding-top: 8%;
  margin-left: 30px;
  text-align: center;
}

.underline {
  margin-left: 29%;
  align-content: center;
}

.ConnectButton {
  margin-left: 180px;
  margin-top: -3%;
  border-radius: 50%;
}

.MetaMaskIcon {
  margin-left: 10%;
}

.MetaMaskDesc {
  max-width: 80%;
  margin-left: 28%;
  margin-top: -12%;
}
