@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@100;300;400;500;600;700;800&display=swap");

* {
  max-width: 100%;
  box-sizing: border-box;
}

h1,
h4,
p,
a {
  color: #fff;
}

body {
  margin: 0;
  font-family: "Outfit", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;
}
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}

.btn-light {
  background: rgba(255, 255, 255, 0.2);
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
}
