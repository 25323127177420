.GameOverView {
  display: flex;
}
.picture {
  float: left;
  width: 45%;
  height: 45%;

  margin-top: 80px;
  margin-left: 30px;
}

.content1 {
  margin-top: 60px;
  max-height: 100vh;
}

.def {
  margin-left: 8%;

  /* text-align: justify; */
  font-size: 50px;
  font-weight: 500;
}

.desc {
  /* text-align: justify; */
  float: right;
  max-width: 80%;
  margin-right: 95px;
  /* font-weight: bold; */
  margin-top: 50px;
  font-size: x-large;
}

.underline {
  margin-right: 48%;
}

.picture2 {
  float: left;
  width: 45%;
  height: 45%;
  margin-top: 160px;
  margin-left: 30px;
}
